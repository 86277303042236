import './App.css';
import Search from './Components/Search';
import SearchResult from './Components/SearchResult';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Search />}></Route>
          <Route path='/searchresult' element={<SearchResult />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
