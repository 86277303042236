import React, { useState } from 'react'
import logo from '../image/logo-300.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const SearchResult = () => {
    const location = useLocation();
    const [state, setState] = useState();
    const history = useNavigate();

    const handleChange = () => {
        setState(location.state);
        history(`/searchresult`, {
            state: {
                "folio": location.state.folio,
                "name": location.state.name,
                "title": location.state.title,
                "issuance": location.state.issuance,
                "completion": location.state.completion,
            }
        });
    }

    return (
        <>
            <section className='bg-image '>
                <div className="bg ">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-xl-10">
                            <div className="text-black">
                                <div className="row g-0">
                                    <div className=''>
                                        <div className=''>
                                            <div className="card-body text-white">
                                                <div className='d-flex row'>
                                                    <div className="col-3 col-md-2">
                                                        <Link to='https://uanverificacion.com/'>
                                                            <img src={logo} className='image ms-2' alt='' />
                                                        </Link>
                                                    </div>

                                                    <div className="row d-flex col-9 col-md-10">
                                                        <div className='col-lg-8 col-sm-12 pe-0'>
                                                            <ul className='nav mt-2 mt-lg-4 text-end justify-content-end text-lg-start justify-content-lg-start'>
                                                                <li
                                                                    className='nav-item fw-semibold'>
                                                                    <h2
                                                                        className='heading-font text-white text-decoration-none text-uppercase mx-2 border-bottom border-danger border-2 pb-1 mb-sm-3'>
                                                                        CONSULTA DE TITULOS O GRADOS
                                                                    </h2>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='col-lg-4 col-sm-12'>
                                                            <ul className='nav text-end justify-content-end text-sm-center mt-0 mt-lg-4'>
                                                                <li className='nav-item fw-semibold' >
                                                                    <Link
                                                                        className='text-white text-decoration-none text-uppercase mx-2 nav-text'
                                                                        to='https://www.universidad-de-america-del-norte.com/contacto/'
                                                                        style={{ cursor: 'pointer' }}
                                                                        target='_blank'
                                                                    >
                                                                        inicio
                                                                    </Link>
                                                                </li>
                                                                <li
                                                                    className='nav-item fw-semibold'>
                                                                    <Link
                                                                        className='text-white text-decoration-none text-uppercase mx-2 nav-text fw-normal'
                                                                        to="https://www.universidad-de-america-del-norte.com/contacto/"
                                                                        style={{ cursor: 'pointer' }}
                                                                        target='_blank'
                                                                    >
                                                                        contáctanos
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mt-4 offset-lg-2 mx-lg-0 mx-md-3 fw-semibold text-uppercase'>
                                                    Nuestra página de verificación de titulación es una herramienta confiable, diseñada para agilizar el
                                                    proceso de verificación y brindar tranquilidad a aquellos que requieran validar la formación académica
                                                    de nuestros egresados. A través de nuestra plataforma, usted podrá acceder a la información pertinente
                                                    de la titulación y certificados, garantizando así una verificación eficiente y precisa.
                                                </div>

                                                <div className='my-5 mx-md-4 pt-lg-5 d-lg-flex'>
                                                    <div className="form-outline col-lg-6 pe-ld-2">
                                                        <div className="form-group row align-items-center">
                                                            <label htmlFor="folio" className="col-lg-6 col-md-5 fw-bold">FOLIO</label>
                                                            <div className="col-lg-6 col-md-7 pe-3">
                                                                <span className="search-info" id="folio" >{location && location.state.folio} </span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row mt-5 align-items-center">
                                                            <label className="col-lg-6 col-md-5 fw-bold">NOMBRE</label>
                                                            <div className="col-lg-6 col-md-7 pe-3">
                                                                <span className="search-info" id='name'>{location && location.state.name}</span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row mt-5 align-items-center">
                                                            <label className="col-lg-6 col-md-5 fw-bold">TITULO O GRADO</label>
                                                            <div className="col-lg-6 col-md-7 pe-3">
                                                                <span className="search-info" id='title'>{location && location.state.title} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-outline col-lg-6 ps-lg-2 align-items-center">
                                                        <div className="form-group row mt-5 pt-lg-5 align-items-center">
                                                            <label className="col-lg-6 col-md-5 text-lg-end fw-bold">FECHA DE TERMINACIÓN:</label>
                                                            <div className="col-lg-6 col-md-7 pe-3">
                                                                <span className="search-info" id='issuance'>{location && location.state.issuance}</span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row mt-5 align-items-center">
                                                            <label className="col-lg-6 col-md-5 text-lg-end fw-bold" >FECHA DE EXPEDICIÓN:</label>
                                                            <div className="col-lg-6 col-md-7 pe-3">
                                                                <span className="search-info" id='completion'>{location && location.state.completion}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SearchResult