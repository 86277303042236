import React, { useState } from 'react'
import image from '../image/logo-300.png'
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const Search = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState("")
    const [error, setError] = useState(false);
    const [textError, setTextError] = useState(false);

    const handleChange = (e) => {
        let value = e.target.value
        if (value.length > 0) {
            setSearch(value)
            setTextError(false)
        }
        else {
            setTextError(true);
        }
    }
    const errorMessage = () => {
        setError(false)
        setSearch("")
    }
    const handleSearch = async (e) => {
        e.preventDefault();
        if (search.length > 0) {
            setTextError(false)
            const user = await fetch(`/admin/search.php?s=${search}`)
            const filterData = await user.json()
            if (filterData.status === false) {
                document.getElementById('search').value = ''
                setError(true)
            }
            else {
                console.log("ccc");
                navigate(`/searchresult`, {
                    state: {
                        "folio": filterData.data.folio,
                        "name": filterData.data.name,
                        "title": filterData.data.title_or_degree,
                        "issuance": filterData.data.issuance_date,
                        "completion": filterData.data.completion_date,
                    }
                });
                // document.getElementById('search').value = ''
                setError(false)
            }
        }
        else {
            setTextError(true);
            setError(false);
        }
    }

    const onKeyPressHandler = (e) => {
        // e.preventDefault();
        if (e.keyCode == 13) {
            handleSearch(e);
        }
        else { }
    };
    return (
        <>
            <section className="home" onKeyDown={onKeyPressHandler}>
                <div className="py-5">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-xl-12">
                            <div className="row g-0">
                                <div className="col-lg-6 d-md-block d-flex ">
                                </div>
                                <div className="col-lg-6">
                                    <div className="card-body mt-2  text-white">
                                        <div className='d-flex row'>
                                            <div className="col-4">
                                                <img src={image} className='image' alt='' />
                                            </div>
                                            <div className='col-8 content-right'>
                                                <ul className='nav text-end justify-content-end mt-4 me-3'>
                                                    <li className='nav-item fw-semibold'>
                                                        <Link className='text-white text-decoration-none text-uppercase mx-2 nav-text'
                                                            to='https://www.uanmexico.net/'
                                                            target='_blank'
                                                        >
                                                            sitio web
                                                        </Link>
                                                    </li>
                                                    <li className='nav-item fw-semibold'>
                                                        <Link className='text-white text-decoration-none text-uppercase mx-2 nav-text fw-normal'
                                                            to='https://www.universidad-de-america-del-norte.com/contacto/'
                                                            target='_blank'
                                                        >
                                                            contáctanos
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <form className='mt-5 mx-md-4 px-md-5 pt-lg-5'>
                                            <div className="form-outline">
                                                <h5 className="pb-2 text-center pt-5 pt-lg-0 heading mx-auto">CONSULTA DE TITULOS O GRADOS</h5>
                                                <div className='form-group position-relative'>
                                                    <input
                                                        type="search"
                                                        id='search'
                                                        name='search'
                                                        className="form-control text-center search-font"
                                                        placeholder="Ingresa tu Folio"
                                                        aria-label="search"
                                                        aria-describedby="search-addon"
                                                        autoComplete='off'
                                                        onChange={handleChange}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-light active"
                                                        onClick={handleSearch}>
                                                        <SearchIcon />
                                                    </button>
                                                </div>
                                                {/* { */}
                                                {/* textError && textError.length && */}
                                                <div
                                                    className="text-center navlink-text err-msg"
                                                    style={{ display: textError === true ? 'block' : 'none' }}
                                                >
                                                    Por favor Ingresa tu Folio
                                                </div>
                                                {/* } */}
                                            </div>
                                        </form>
                                    </div>
                                    <div className="text-center mt-3 text-white">
                                        <Link className='text-decoration-none text-white navlink-text' to='#'>¿No puedes accedor?</Link>
                                        <br />
                                        <Link
                                            className='mt-1 text-white mt-5 navlink-text'
                                            to='https://www.universidad-de-america-del-norte.com/contacto/'
                                            target='_blank'
                                        >
                                            contáctanos
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center error pb-3" style={{ display: error === true ? "block" : 'none' }} id="error">
                                <div className='text-end text-white' style={{ cursor: 'pointer' }}><CloseIcon onClick={errorMessage} /></div>
                                <div className='row d-flex align-items-center m-0 px-3 mb-2'>
                                    <div className='col col-2 text-center text-white fs-1 fs-md-3'>
                                        <div className='text-center bg-danger rounded-circle crose-sign' > X </div>
                                    </div>
                                    <div className='col col-10'>
                                        <p className='text-white text-center fs-4 mb-0 err-msg'>
                                            Tu folio es incorrecto o no esta verificado por la Universidad
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default Search